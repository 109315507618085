import React from "react";
import { Button, Grid, Header, Icon, Menu } from "semantic-ui-react";

const sharedStyle = {
  margin: "0 0.3em",
  fontSize: "1.2em",
  background: "white",
  border: "1px solid black",
  borderRadius: "16px",
};

const Navbar = () => {
  return (
    <Menu style={{ padding: "0 0 0 0.5em" }} text>
      <Menu.Item>
        <Header size="huge" style={{ fontSize: "1.75em" }}>
          uid4oe
        </Header>
      </Menu.Item>
      <Menu.Item position="right">
        <Grid relaxed>
          <Button
            style={sharedStyle}
            icon={<Icon name="linkedin" color="blue" fitted />}
            color="blue"
            role="a"
            target="_blank"
            href="https://www.linkedin.com/in/uid4oe"
          />
          <Button
            style={sharedStyle}
            icon={<Icon name="github" color="black " fitted />}
            role="a"
            target="_blank"
            href="https://github.com/uid4oe"
          />
          <Button
            style={sharedStyle}
            icon={<Icon name="medium" color="black" fitted />}
            color="teal"
            role="a"
            target="_blank"
            href="https://uid4oe.medium.com"
          />
        </Grid>
      </Menu.Item>
    </Menu>
  );
};

export default Navbar;
